import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';

import useSubscription from '@/hooks/account/useSubscription';
import useFeatureFlags from '@/services/FeatureFlag';

const SubscriptionBanner = ({ size }: { size?: string }) => {
  const { t } = useTranslation();
  const { isSubscribed, isMonthly, isFreeTrial } = useSubscription();
  const { isPricingTestGroup } = useFeatureFlags();
  return (
    <div className="subscription-banner">
      {size !== 'small' && (
        <img className="banner-image" src="https://cdn.unhurd.co.uk/images/web/subscription-banner-image.webp" alt="" />
      )}
      <div className="z-1000 pos-rel">
        {!isSubscribed && (
          <div className="d-flex gap8 flex-wrap jc-space-between">
            <div>
              <img className="logo pr8" src="/images/logos/full-text-logo.svg" alt="" />
              <img className={`pro-badge`} src="/images/logos/subscription-pro-badge.svg" alt="" />
            </div>

            {size === 'small' && (
              <Button className="no-bg p0 m0 mt-2 mb-auto">
                {t('SUBSCRIPTION.START-FREE-TRIAL')}
                <Icon>chevron_right</Icon>
              </Button>
            )}
          </div>
        )}
        {isSubscribed && (
          <div>
            <h3>You're an un:hurd music pro</h3>
            <p className="text-faded mb48">
              {isFreeTrial ? 'Free trial' : isMonthly ? 'Monthly subscription' : 'Annual subscription'}
            </p>
          </div>
        )}
        {!isSubscribed && (
          <p className={`${size !== 'small' ? 'mt20' : 'mt0 banner-small text-faded'}`}>
            {isPricingTestGroup ? t('SUBSCRIPTION.SUBSCRIPTION-TAG-LINE-ALT') : t('SUBSCRIPTION.SUBSCRIPTION-TAG-LINE')}
          </p>
        )}
        {size !== 'small' && !isSubscribed && (
          <Button className="no-bg p0 m0 mt10">
            {t('SUBSCRIPTION.START-FREE-TRIAL')}
            <Icon>chevron_right</Icon>
          </Button>
        )}
      </div>
    </div>
  );
};

export default SubscriptionBanner;
